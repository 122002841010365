import { useTranslation } from "react-i18next"
import './footer.scss';
import Facebook from 'assets/images/Facebook-icon.svg';
import Instagram from 'assets/images/Instagram-icon.svg';
import Twitter from 'assets/images/Twitter-icon.svg';
import { URLs } from "urls";
import { useEffect, useState } from "react";
import { flexRowOrColumn, useWindowSize } from "helper/Classhelper";
import { Link } from "react-router-dom";

export const Footer = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
  
    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    return (
        <footer className="bg-light-pink">
            <div className={`ahsi-footer d-flex justify-content-between ${flexRowOrColumn(isDesktop)}`}>
                <div className="footer-section-left d-flex flex-column mb-50">
                    <span className="title">Academic Health Science Institute (AHSI)</span>
                    <span className="description">{t('footer.description')}</span>
                    <span className="social-icons d-inline-flex flex-row">
                        <a href="/">
                            <span className="social-img-bg d-flex align-items-center justify-content-center">
                                <img src={Facebook} alt='fb-icon' />
                            </span>
                        </a>
                        <a href="/">
                            <span className="social-img-bg d-flex align-items-center justify-content-center">
                                <img src={Instagram} alt="insta-icon"/>
                            </span>
                        </a>
                        <a href="/">
                            <span className="social-img-bg d-flex align-items-center justify-content-center">
                                <></><img src={Twitter} alt="twitter-icon"/>
                            </span>
                        </a>
                    </span>            
                </div>

                <div className={`footer-section-right d-flex ${flexRowOrColumn(isDesktop)}`}>
                    <div className={`d-flex justify-content-between  flex-row`}>
                        <div className="footer-right-sub-section d-flex flex-column mr-87">
                            <span className="title">{t('footer.resources')}</span>
                            <ul className="list-without-bullets">
                                <li><a href={URLs.SERVICES}>{t('footer.services')}</a></li>
                                <li><a href={URLs.WHYAHSI}>{t('footer.whyahsi')}</a></li>
                                <li><a href="/">{t('footer.blogs')}</a></li>
                            </ul>
                        </div>
                        <div className="footer-right-sub-section d-flex flex-column mr-87">
                            <span className="title">{t('footer.company')}</span>
                            <ul className="list-without-bullets">
                                <li><a href="/">{t('footer.careers')}</a></li>
                                <li><a href={URLs.ABOUT}>{t('footer.about')}</a></li>
                                <li><a href="/">{t('footer.contact')}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-right-sub-section d-flex flex-column">
                        <span className="title">{t('footer.legal')}</span>
                        <ul className="list-without-bullets">
                            <li><Link to={URLs.USER_TERMS}>{t('footer.terms')}</Link></li>
                            <li><Link to={URLs.PRIVACY}>{t('footer.privacy')}</Link></li>
                            <li><Link to={URLs.COOKIE}>{t('footer.cookie')}</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <span className="copyrights d-flex align-items-center justify-content-center">
                Copyright © 2022 AHSI. All rights reserved.
            </span>
        </footer>
    );
}