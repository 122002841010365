import { useTranslation } from "react-i18next"

import EmailIcon from 'assets/images/email-icon.svg';
import PhoneIcon from 'assets/images/phone-icon.svg';
import LocationIcon from 'assets/images/location-icon.svg';
import ClockIcon from 'assets/images/clock-icon.svg';
import { ADDRESS, EMAIL, PHONE_NUMBER, TIMING } from 'constants/ContactDetailsSection';

import './ContactDetailSection.scss';
import { flexRowOrColumn, useWindowSize } from "helper/Classhelper";
import { useEffect, useState } from "react";

export const ContactDetailSection = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
  
    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    return (
        <div className='contact-detail-section d-flex flex-column justify-content-center align-items-center'>
            <div className='contact-detail-sub-section d-flex flex-column justify-content-center align-items-center'>
            <small className='sub-section-pink-text'>{t('contact.appointment')}</small>
            <span className='sub-section-text text-center'>{t('contact.visit')}</span>
            </div>

            <div className={`contact-section d-flex justify-content-center align-items-center ${flexRowOrColumn(isDesktop)}`}>
            <div className='contact-sub-section d-flex flex-column'>
                <span className='contact-details-title'>{t('contact.ourDetails')}</span>
                <div className={`contact-email-phone d-flex justify-content-between ${flexRowOrColumn(isDesktop)}`}>
                    <span className={`${(!isDesktop && 'mb-30 ml-60')}`}><img src={EmailIcon} alt='email-icon' className='mr-2'/>{EMAIL}</span>
                    <span className={`${(!isDesktop && 'ml-60')}`}><img src={PhoneIcon} alt='phone-icon' className='mr-2'/>{PHONE_NUMBER}</span>
                </div>
                <div className='contact-email-phone d-inline-flex justify-content-between'>
                    <img src={LocationIcon} alt='location-icon' className={`mr-2  ${(!isDesktop && 'ml-60')}`}/>
                    <span>{ADDRESS}</span>
                </div>
                <span className='contact-details-title'>{t('contact.businessHours')}</span>
                <div className={`contact-email-phone d-inline-flex ${(!isDesktop && 'ml-60')}`}>
                <img src={ClockIcon} alt='clock-icon'/>
                <span>{TIMING}</span>
                </div>
            </div>
            <span className='doctor-image-section'>
                <iframe 
                title='map' 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d217759.4898312831!2d74.19430439269996!3d31.483156883511057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190483e58107d9%3A0xc23abe6ccc7e2462!2sLahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1654180329892!5m2!1sen!2s" 
                width={isDesktop ? "440" : "313"} 
                height="468" 
                loading="lazy">
                </iframe>
            </span>
            </div>
        </div>
    );
}