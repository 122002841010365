import { useTranslation } from 'react-i18next';
import './CoreValuesSection.scss'
import { Value } from './Value';

export const CoreValuesSection = () => {
    const { t } = useTranslation();

    return (
        <div className="core-values-section d-flex flex-column">
            <div className="core-values-title-section">
                <span className="title">
                    {t('corevalues.heading')}
                </span>
            </div>

            <div className="core-values-detail-section d-flex justify-content-between">
                <Value
                    valueNumber='01.'
                    valueHeading={t('corevalues.value.professionalism')}
                    valueDescription={t('value.professionalism.description')}
                />

                <Value
                    valueNumber='02.'
                    valueHeading={t('corevalues.value.individualism')}
                    valueDescription={t('value.individualism.description')}
                />
            </div>

            <div className="core-values-detail-section d-flex justify-content-between">
                <Value
                    valueNumber='03.'
                    valueHeading={t('corevalues.value.empowerment')}
                    valueDescription={t('value.empowerment.description')}
                />

                <Value
                    valueNumber='04.'
                    valueHeading={t('corevalues.value.commitment')}
                    valueDescription={t('value.commitment.description')}
                />
            </div>

            <div className="core-values-detail-section d-flex justify-content-between">
                <Value
                    valueNumber='05.'
                    valueHeading={t('corevalues.value.empathy')}
                    valueDescription={t('value.empathy.description')}
                />
            </div>
        </div>
    );
}