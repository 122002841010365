import { Services } from "constants/Services";
import { useCallback, useEffect, useState } from "react";
import Arrow from 'assets/images/polygon-icon.svg';
import './OurServicesSection.scss'
import { flexRowOrColumn, useWindowSize } from "helper/Classhelper";
import OurServicesForMob from "../OurServicesForMob/OurServicesForMob";
import { Link } from "react-router-dom";
import { URLs } from "urls";

export const OurServicesSection = ({ isHomepage = false }) => {
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
    const [selectedService, setSelectedService] = useState<string>(Services.SUBFERTILITY_SERVICES.name);
    const [currentServices, setCurrentServices] = useState<string[]>(Services.SUBFERTILITY_SERVICES.services);

    const isSelectedService = useCallback((service: string) => selectedService === service, [selectedService]);

    useEffect(() => {
        setIsDesktop(width > 1024);
      }, [width]);

    return (
        <div  className={`our-services d-flex flex-column align-items-center ${!isDesktop || isHomepage  ? 'bg-pink' : 'bg-white'} ${ width>1024 && selectedService === "Gynecology Services"  ? 'heightForMaxService':''} ${ width>1024 && width<1300 && selectedService === "Gynecology Services"  ? 'heightForOtherServices':''} `} >
            <div className="our-services-sub-section d-flex flex-column justify-content-center align-items-center">
                <small className='mt-3 sub-section-pink-text'>{isHomepage ? 'Our Services' : 'What we offer'}</small>
                <span className='sub-section-text text-center' style={{width: !isDesktop ? '313px': '414px',lineHeight:'49px'}}>Creating a Healthier Future for All Women</span>
            </div>
            { isDesktop
            ?
            <div className={`services-section d-flex justify-content-between align-items-center ${flexRowOrColumn(isDesktop)}`}>
                <div className="d-flex flex-column button-group">
                    {Object.entries(Services).map((service) => {
                        return (
                            <div className={`mb-30 ${isDesktop ? 'mr-36' : ''}`} key={service[0]}>
                                <button
                                    className={`button ${isHomepage ? 'bg-white' : 'bg-light-pink'} ${isSelectedService(service[1].name) && 'active'}`}
                                    onClick={() => {
                                        setSelectedService(service[1].name)
                                        setCurrentServices(service[1].services);
                                    }}>
                                        <span>
                                            {service[1].name}
                                            {isSelectedService(service[1].name) && <img className="rotate-90" src={Arrow} alt="polygon-icon"/>}
                                        </span>
                                </button>
                            </div>
                        )
                    })}
                </div>

                <div className={`services-list-section d-flex flex-column list-section`}>
                    <span className="description mb-27">Our {selectedService} Include The Following:</span>
                    <span className="pink-border"/>
                    <hr className="solid" />
                    <ul className="services-list">
                        {currentServices.map((service) => (
                            <li key={service}>{service}</li>
                        ))}
                    </ul>
                    {isHomepage ? (
                        <Link to={URLs.SERVICES}>
                            <button className="learn-more-btn" style={{backgroundColor:"transparent"}}>
                                Learn More
                            </button>
                        </Link>
                    ) : <></>}
                </div>
                
            </div>
            :
            <>
            <OurServicesForMob isHomepage />
            </>}
        </div>
    );
}