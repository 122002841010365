import { useWindowSize } from 'helper/Classhelper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./ArticlesSection.scss"

export const ArticlesSection = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    return (
        <>
            <div className='blogs-detail-section d-flex flex-column'>
                <div className='blogs-detail-sub-section d-flex flex-column justify-content-center align-items-center'>
                    <span className='sub-section-text text-center'>{t('articles.latest')}</span>
                </div>
                <div className='blogs-detail-sub-section d-flex flex-column justify-content-center align-items-center'>
                    <br/>
                    <a className='sub-section-pink-text' href='publications/Breast feeding Experience.pdf' download>Breast feeding Experience  <small className='small-download'>(Click to Download the PDF)</small></a><br/>
                    <a className='sub-section-pink-text' href='publications/Foxo and decidua.pdf.pdf' download>Foxo and decidua <small className='small-download'>(Click to Download the PDF)</small></a><br/>
                    <a className='sub-section-pink-text' href='publications/Foxo in endometrial cancer.pdf' download>Foxo in endometrial cancer <small className='small-download'>(Click to Download the PDF)</small></a><br/>
                    <a className='sub-section-pink-text' href='publications/Maternal Position in Labour.pdf' download>Maternal Position in Labour <small className='small-download'>(Click to Download the PDF)</small></a><br/>
                    <a className='sub-section-pink-text' href='publications/Unexplained subfertility SGK1.pdf' download>Unexplained subfertility SGK1 <small className='small-download'>(Click to Download the PDF)</small></a><br/>
                    <a className='sub-section-pink-text' href='publications/Unexplained subfertility-proteomic analysis.pdf' download>Unexplained subfertility-proteomic analysis <small className='small-download'>(Click to Download the PDF)</small></a><br/>
                    <a className='sub-section-pink-text' href='publications/Unexplained Subfertility.pdf' download>Unexplained Subfertility <small className='small-download'>(Click to Download the PDF)</small></a>
                </div>
            </div>
        </>
    );
}
