import { Services } from 'constants/Services'
import { useWindowSize } from 'helper/Classhelper';
import { useCallback, useEffect, useState } from 'react';
import './OurServicesForMob.scss';
import Arrow from 'assets/images/polygon-icon.svg';
import pinkArrow from 'assets/images/pinkArrow.svg';
import { Link } from 'react-router-dom';
import { URLs } from 'urls';

const OurServicesForMob = ({ isHomepage = false }) => {

    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
    const service1=Services.SUBFERTILITY_SERVICES.name;
    const service2=Services.METERNITY_SERVICES.name;
    const service3=Services.OBSTETRIC_PROCEDURES.name;
    const service4=Services.GYNECOLOGY_SERVICES.name;
    const service5=Services.GYNAE_PROCEDURES.name;
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const isSelectedService = useCallback((service: string) => selectedService === service, [selectedService]);

    useEffect(()=>
        setIsDesktop(width>1024)
    ,[width])
    return (
        <div className={`services2-section d-flex justify-content-between align-items-center flex-column`}>
            <div className={`mb-4 ${isDesktop ? 'mr-36' : ''}`} >
                <button
                    className={`button ${isHomepage ? 'bg-white' : 'bg-light-pink'} ${isSelectedService(service1) && 'active'}`}
                    onClick={() => {
                        if(!isSelectedService(service1))
                        {
                            setSelectedService(service1)
                        }
                        else
                            setSelectedService("");
                    }}>
                        <span className='ms-5 d-flex justify-content-center'>
                            {service1}
                            <div className="ms-5">
                                {isSelectedService(service1) ? <img className='selected-arrow' src={Arrow} alt="polygon-icon"/> 
                                    :
                                <img  className="unselected-arrow" src={pinkArrow} alt="polygon-icon"/>}
                            </div>
                        </span>
                </button>
                {
                    isSelectedService(service1) &&
                <div className={`services-list-section  d-flex flex-column list-section`}>
                    <span className="mt-2 description mb-27">Our {selectedService} Include The Following:</span>
                    <span className="pink-border"/>
                    <hr className="solid " style={{marginBottom:'30px'}}/>
                    <ul className="services-list">
                        {Services.SUBFERTILITY_SERVICES.services.map((service) => {
                        return(    
                            <li key={service}>{service}</li>
                        )})}
                    </ul>
                    <Link to={URLs.SERVICES}>
                        <button className="learn-more-btn" style={{backgroundColor:"transparent",width:'265px'}}>
                            Learn More
                        </button>
                    </Link>
                </div>
                }
            </div>
            <div className={`mb-4 ${isDesktop ? 'mr-36' : ''}`} >
                <button
                    className={`button ${isHomepage ? 'bg-white' : 'bg-light-pink'} ${isSelectedService(service2) && 'active'}`}
                    onClick={() => {
                        if(!isSelectedService(service2))
                        {
                            setSelectedService(service2)
                        }
                        else
                            setSelectedService("")
                    }}>
                        <span className='ms-5 d-flex justify-content-center'>
                            {service2}
                            <div className="ms-4">
                                {isSelectedService(service2) ? <img className='selected-arrow' src={Arrow} alt="polygon-icon"/> 
                                    :
                                <img  className="unselected-arrow" src={pinkArrow} alt="polygon-icon"/>}
                            </div>
                        </span>
                </button>
                {
                    isSelectedService(service2) &&
                <div className={`services-list-section  d-flex flex-column list-section`}>
                    <span className="mt-2 description mb-27">Our {selectedService} Include The Following:</span>
                    <span className="pink-border"/>
                    <hr className="solid " style={{marginBottom:'30px'}}/>
                    <ul className="services-list">
                        {Services.METERNITY_SERVICES.services.map((service) => {
                        return(    
                            <li key={service}>{service}</li>
                        )})}
                    </ul>
                    <Link to={URLs.SERVICES}>
                        <button className="learn-more-btn" style={{backgroundColor:"transparent",width:'265px'}}>
                            Learn More
                        </button>
                    </Link>
                </div>
                }
            </div>
            <div className={`mb-4 ${isDesktop ? 'mr-36' : ''}`} >
                <button
                    className={`button ${isHomepage ? 'bg-white' : 'bg-light-pink'} ${isSelectedService(service3) && 'active'}`}
                    onClick={() => {
                        if(!isSelectedService(service3))
                        {
                            setSelectedService(service3)
                        }
                        else
                            setSelectedService("")
                    }}>
                        <span className='ms-5 d-flex justify-content-center'>
                            {service3}
                            <div className="ms-5">
                                {isSelectedService(service3) ? <img className='selected-arrow' src={Arrow} alt="polygon-icon"/> 
                                    :
                                <img  className="unselected-arrow" src={pinkArrow} alt="polygon-icon"/>}
                            </div>
                        </span>
                </button>
                {
                    isSelectedService(service3) &&
                <div className={`services-list-section  d-flex flex-column list-section`}>
                    <span className="mt-2 description mb-27">Our {selectedService} Include The Following:</span>
                    <span className="pink-border"/>
                    <hr className="solid " style={{marginBottom:'30px'}}/>
                    <ul className="services-list">
                        {Services.OBSTETRIC_PROCEDURES.services.map((service) => {
                        return(    
                            <li key={service}>{service}</li>
                        )})}
                    </ul>
                    <Link to={URLs.SERVICES}>
                        <button className="learn-more-btn" style={{backgroundColor:"transparent",width:'265px'}}>
                            Learn More
                        </button>
                    </Link>
                </div>
                }
            </div>
            <div className={`mb-4 ${isDesktop ? 'mr-36' : ''}`} >
                <button
                    className={`button ${isHomepage ? 'bg-white' : 'bg-light-pink'} ${isSelectedService(service4) && 'active'}`}
                    onClick={() => {
                        if(!isSelectedService(service4))
                        {
                            setSelectedService(service4)
                        }
                        else
                            setSelectedService("")
                    }}>
                        <span className='ms-5 d-flex justify-content-center'>
                            {service4}
                            <div className="ms-5">
                                {isSelectedService(service4) ? <img className='selected-arrow' src={Arrow} alt="polygon-icon"/> 
                                    :
                                <img  className="unselected-arrow" src={pinkArrow} alt="polygon-icon"/>}
                            </div>
                        </span>
                </button>
                {
                    isSelectedService(service4) &&
                <div className={`services-list-section  d-flex flex-column list-section`}>
                    <span className="mt-2 description mb-27">Our {selectedService} Include The Following:</span>
                    <span className="pink-border"/>
                    <hr className="solid " style={{marginBottom:'30px'}}/>
                    <ul className="services-list">
                        {Services.GYNECOLOGY_SERVICES.services.map((service) => {
                        return(    
                            <li key={service}>{service}</li>
                        )})}
                    </ul>
                    <Link to={URLs.SERVICES}>
                        <button className="learn-more-btn" style={{backgroundColor:"transparent",width:'265px'}}>
                            Learn More
                        </button>
                    </Link>
                </div>
                }
            </div>
            <div className={`mb-4 ${isDesktop ? 'mr-36' : ''}`} >
                <button
                    className={`button ${isHomepage ? 'bg-white' : 'bg-light-pink'} ${isSelectedService(service5) && 'active'}`}
                    onClick={() => {
                        if(!isSelectedService(service5))
                        {
                            setSelectedService(service5)
                        }
                        else
                            setSelectedService("")
                    }}>
                        <span className='ms-5 d-flex justify-content-center'>
                            {service5}
                            <div className="m-62">
                                {isSelectedService(service5) ? <img className='selected-arrow' src={Arrow} alt="polygon-icon"/> 
                                    :
                                <img  className="unselected-arrow" src={pinkArrow} alt="polygon-icon"/>}
                            </div>
                        </span>
                </button>
                {
                    isSelectedService(service5) &&
                <div className={`services-list-section  d-flex flex-column list-section`}>
                    <span className="mt-2 description mb-27">Our {selectedService} Include The Following:</span>
                    <span className="pink-border"/>
                    <hr className="solid " style={{marginBottom:'30px'}}/>
                    <ul className="services-list">
                        {Services.GYNAE_PROCEDURES.services.map((service) => {
                        return(    
                            <li key={service}>{service}</li>
                        )})}
                    </ul>
                    <Link to={URLs.SERVICES}>
                        <button className="learn-more-btn" style={{backgroundColor:"transparent",width:'265px'}}>
                            Learn More
                        </button>
                    </Link>
                </div>
                }
            </div>
        </div>
    )
    }
export default OurServicesForMob
