interface Props {
    valueNumber: string;
    valueHeading: string;
    valueDescription: string;
}

export const Value = ({ valueNumber, valueHeading, valueDescription }: Props) => (
    <div className="value d-flex flex-column">
        <span className="d-inline-flex mb-15">
            <span className="value-number">{valueNumber}</span>
            <span className="value-title">{valueHeading}</span>
        </span>
        <span className="value-description">{valueDescription}</span>
    </div>
)