import { flexRowOrColumn, useWindowSize } from "helper/Classhelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLs } from "urls";
import { SharedButton } from "../SharedButton/SharedButton";
import './FindingSolutions.scss'

interface Props {
    componentName: string;
}

export const FindingSolutions = ({ componentName }: Props) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
  
    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);
    const imageUrl = `${componentName}-finding-solution-img`;

    return (
        <div className={`finding-solutions d-flex justify-content-start ${flexRowOrColumn(isDesktop)} align-items-center align-items-left ${imageUrl}`}>
          <div className='finding-solutions-section d-flex flex-column'>
            <small className='text-sm'>{t('solutions.werehere')}</small>
            <span className='text-lg'>{t('solutions.excellence')}</span>
            <Link to={URLs.BOOK_AN_APPOINTMENT} className="mt-35">
              <SharedButton
                isBookingButton
                btnText={t("shared.book_button")}
              />
            </Link>
          </div>
        </div>
    );
};