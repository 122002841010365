import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { DoctorDetailSection } from "shared/components/DoctorDetailSection/DoctorDetailSection";
import { FindingSolutions } from "shared/components/FindingSolutions/FindingSolutions";
import { OurServicesSection } from "shared/components/OurServicesSection/OurServicesSection";
import { FeedBackForm } from "shared/components/FeedBackForm/FeedBackForm";
import { SharedButton } from "shared/components/SharedButton/SharedButton";
import { Testimonial } from "shared/components/Testiomonial/Testimonial";
import { VisionSection } from "shared/components/VisionSection/VisionSection";
import { BlogsSection } from "./BlogsSection/BlogsSection";
import { ArticlesSection } from "./Articles/ArticlesSection";
import { Components } from "shared/constants/components";
import { URLs } from "urls";
import "./homepage.scss";
import { MemberShip } from "components/MemberShip/MemberShip";
import { useEffect, useState } from "react";
import { flexRowOrColumn, useWindowSize } from "helper/Classhelper";
import { Projects } from "components/Projects/Projects";
import { useSpring, animated } from "react-spring";
import { ContactDetailSection } from "shared/components/ContactDetailSection/ContactDetailSection";
import { Carousel } from "react-bootstrap";

export const Homepage = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
  const [index, setIndex] = useState<number>(0);
  const { pathname } = useLocation();
  
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setIsDesktop(width > 1024);
  }, [width]);

  const props = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: -500 },
  });
  return (
    <animated.div style={props}>
      <Carousel className="carousel-home-section"
        fade
        interval={2500}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <div className="home-container carousel-one-image d-flex  ">
            <div className={`d-flex home-content ${flexRowOrColumn(isDesktop)}`}>
              <div className="home-main-text">
                <div className="d-flex flex-column">
                  <span className="home-main-span-text ahsi">
                    {t("homepage.ahsi")} 
                  </span>
                  <span className="home-main-des-text">
                    {t("homepage.main_content")}
                  </span>
                </div>
                <div className={`d-flex ${flexRowOrColumn(isDesktop)}`}>
                  <Link to={URLs.BOOK_AN_APPOINTMENT} className="mt-30 mb-15">
                    <SharedButton
                      isBookingButton
                      btnText={t("shared.book_button")}
                    />
                  </Link>
                  <Link to={URLs.BOOK_AN_APPOINTMENT}>
                    <button className="contact-us-btn">
                      {t("shared.contact_button")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="home-container carousel-two-image d-flex  ">
            <div className={`d-flex home-content ${flexRowOrColumn(isDesktop)}`}>
              <div className="home-main-text">
                <div className="d-flex flex-column">
                  <span className="home-main-span-text ahsi">
                    {t("homepage.ahsi")} 
                  </span>
                  <span className="home-main-des-text">
                    {t("homepage.main_content")}
                  </span>
                </div>
                <div className={`d-flex ${flexRowOrColumn(isDesktop)}`}>
                  <Link to={URLs.BOOK_AN_APPOINTMENT} className="mt-30 mb-15">
                    <SharedButton
                      isBookingButton
                      btnText={t("shared.book_button")}
                    />
                  </Link>
                  <Link to={URLs.BOOK_AN_APPOINTMENT}>
                    <button className="contact-us-btn">
                      {t("shared.contact_button")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="home-container carousel-three-image d-flex  ">
            <div className={`d-flex home-content ${flexRowOrColumn(isDesktop)}`}>
              <div className="home-main-text">
                <div className="d-flex flex-column">
                  <span className="home-main-span-text ahsi">
                    {t("homepage.ahsi")}
                  </span>
                  <span className="home-main-des-text">
                    {t("homepage.main_content")}
                  </span>
                </div>
                <div className={`d-flex ${flexRowOrColumn(isDesktop)}`}>
                  <Link to={URLs.BOOK_AN_APPOINTMENT} className="mt-30 mb-15">
                    <SharedButton
                      isBookingButton
                      btnText={t("shared.book_button")}
                    />
                  </Link>
                  <Link to={URLs.BOOK_AN_APPOINTMENT}>
                    <button className="contact-us-btn">
                      {t("shared.contact_button")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <DoctorDetailSection isHomepage />

      <VisionSection isHomepage />

      <OurServicesSection isHomepage />

      <FindingSolutions componentName={Components.HOMEPAGE} />

      <FeedBackForm />

      <MemberShip />

      <Testimonial isHomepage />

      <ContactDetailSection />

      <Projects />

      <BlogsSection />
      <ArticlesSection />
    </animated.div>
  );
};
