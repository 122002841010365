export const MemberShipServices = {
  SILVERPLAN_SERVICES: {
    name: "Silver Plan",
    price: "50,000Rs",
    number: "01.",
    Services: [
      "Free consultation with experts",
      "10 % discount on any surgical procedures",
      "medicine review"
    ],
  },
  GOLDPLAN_SERVICES: {
    name: "Gold Plan",
    price: "100,000Rs",
    number: "02.",
    Services: [
      "Free consultation with experts 24/7",
      "20 % discount on any surgical procedure",
      "Medicine review 24/7"
    ],
  },
  // PLATINUMPLAN_SERVICES: {
  //   name: "Platinum Plan",
  //   price: "75,000Rs",
  //   number: "03.",
  //   Services: [
  //     "Service 1",
  //     "Service 2",
  //     "Service 3",
  //     "Service 4",
  //     "Service 5"
  //   ],
  // },
};
