import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FindingSolutions } from "shared/components/FindingSolutions/FindingSolutions";
import { PinkDetailSection } from "shared/components/PinkDetailSection/PinkDetailSection";
import { ContactDetailSection } from "shared/components/ContactDetailSection/ContactDetailSection";

import { Components } from "shared/constants/components";
import { URLs } from "urls";
import { useSpring, animated } from "react-spring";
import { FeedBackForm } from "shared/components/FeedBackForm/FeedBackForm";
import { Testimonial } from "shared/components/Testiomonial/Testimonial";

export const Feedback = () => {
  const props = useSpring({
    to: { marginRight: 0 },
    from: { marginRight: -500 },
  });
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <animated.div style={props}>
      <PinkDetailSection heading="Patient Feedback" url={URLs.FEEDBACK} componentName={Components.FEEDBACK}/>

      <FeedBackForm />

      <FindingSolutions componentName={Components.FEEDBACK} />

      <Testimonial />

      <ContactDetailSection />
    </animated.div>
  );
};
