import { useTranslation } from "react-i18next";
import "./Testimonial.scss";
import ReviewStar from "../../../assets/images/review-star.svg";
import TestimonialImage from "../../../assets/images/testimonial-image.png";

import { Carousel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { flexRowOrColumn, useWindowSize } from "helper/Classhelper";

export const Testimonial = ({ isHomepage = false}) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState<number>(0);
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
  
    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

  return (
    <div className={"testimonial-section d-flex flex-column justify-content-center align-items-center " + (!isHomepage ? 'mt-98' : '')}>
      <div className="testimonial-text mb-3">{t("testimonial.text")}</div>
      <div className="testimonial-say mb-5">{t("testimonial.say")}</div>
      <Carousel
        className="carousel-section"
        interval={null}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <div className={`testimonial-carousel-section d-flex ${flexRowOrColumn(isDesktop)}`}>
            <div className="me-4">
              <img src={TestimonialImage} alt="testimonial-img"/>
            </div>
            <div className="d-flex flex-column p-3">
              <span className="testimonial-text-comment mb-2">
                {t("testimonial1.text.comment")}
              </span>
              <span>
                <img src={ReviewStar} alt="review-star-img"/>
              </span>
              <span className="testimonial-review-name mt-2">
                {t("testimonial1.reviewer.name")}
              </span>
              <span className="testimonial-review-name ">
                on <u>{t("testimonial.google")}</u>
              </span>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={`testimonial-carousel-section d-flex ${flexRowOrColumn(isDesktop) + (!isDesktop && ' align-items-center')}`}>
            <div className="me-4">
              <img src={TestimonialImage} alt="testimonial-img"/>
            </div>
            <div className="d-flex flex-column p-3">
              <span className="testimonial-text-comment mb-2">
                {t("testimonial2.text.comment")}
              </span>
              <span>
                <img src={ReviewStar} alt="review-star-img"/>
              </span>
              <span className="testimonial-review-name mt-2">
                {t("testimonial2.reviewer.name")}
              </span>
              <span className="testimonial-review-name ">
                on <u>{t("testimonial.google")}</u>
              </span>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
