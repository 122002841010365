import { initReactI18next } from 'react-i18next';
import i18n, { Resource } from 'i18next';

type ResourcesParam = Record<string, Record<string, string | Record<string, string>>>;

export const setupI18n = (resources: ResourcesParam): void => {
    i18n.use(initReactI18next).init({
      resources: formatResources(resources),
      fallbackLng: 'en',
  
      interpolation: {
        escapeValue: false,
      },
    });
  };

  const formatResources = (resources: ResourcesParam): Resource =>
  Object.entries(resources).reduce(
    (result, [lang, translation]) => ({
      ...result,
      [lang]: { translation },
    }),
    {}
  ) as unknown as Resource;

export default i18n;
