import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PinkDetailSection } from "shared/components/PinkDetailSection/PinkDetailSection";
import { ContactDetailSection } from "shared/components/ContactDetailSection/ContactDetailSection";
import { URLs } from "urls";
import { Components } from "shared/constants/components";

export const BookAnAppointment = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <>
            <PinkDetailSection heading="Book an Appointment" url={URLs.BOOK_AN_APPOINTMENT} componentName={Components.BOOK_AN_APPOINTMENT}/>
    
            <ContactDetailSection />
        </>
    )
}
