import './App.scss';
import { Homepage } from 'components/homepage/homepage';
import translationsEN from 'translations/en.json';
import { setupI18n } from 'shared/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { About } from 'components/about/about';
import { URLs } from 'urls';
import { WhyAHSI } from 'components/whyahsi/WhyAHSI';
import { OurServices } from 'components/ourservices/ourservices';
import { Feedback } from 'components/feedback/feedback';
import { BookAnAppointment } from 'components/appointment/appointment';
import { Header } from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { Blog } from 'components/blog/blog';
import { ToastContainer } from 'react-toastify';
import { Misc } from 'shared/components/Misc/Misc';
import { Blogs } from 'constants/Blogs';

export const App = () => {
  setupI18n({ en: translationsEN });
  
  return (
    <>
      <Header />
      <Routes>
        <Route path={URLs.ROOT} element={<Homepage />} />
        <Route path={URLs.ABOUT} element={<About />}/>
        <Route path={URLs.WHYAHSI} element={<WhyAHSI />}/>
        <Route path={URLs.FEEDBACK} element={<Feedback />}/>
        <Route path={URLs.SERVICES} element={<OurServices />}/>
        <Route path={URLs.BOOK_AN_APPOINTMENT} element={<BookAnAppointment />}/>
        <Route path={URLs.USER_TERMS} element={<Misc title='User Terms' description={Blogs[0].description} />}/>
        <Route path={URLs.COOKIE} element={<Misc title='Cookie Policy' description={Blogs[0].description} />}/>
        <Route path={URLs.PRIVACY} element={<Misc title='Privacy Policy' description={Blogs[0].description} />}/>
        <Route path="/blog/:id" element={<Blog />}/>
      </Routes>
      <ToastContainer />
      <Footer />
    </>
  );
}
