export const Services = 
{
    SUBFERTILITY_SERVICES: {
        name: "Subfertility Services",
        services: [
            'Subfertility Couple Counselling',
            'Ovulation Induction',
            'IVF Treatment',
            'ICSI Treatment',
            'IUI Treatment',
            'Male Subfertility treatment',
            'IVF Diagnostic Tests',
            'Parent Genetic Sceening',
            'Embryo Genetic Testing',
            'Fertility Assessment',
            'Fertility Preservation',
            'Post conception care'
        ]
    },
    METERNITY_SERVICES: {
        name: "Maternity Care Services",
        services: [
            'Obstetric Care',
            'Early Pregnancy Scans',
            'Pre-conception Assessment',
            'Antenatal Care',
            'Antenatal Consultation',
            'High Risk Pregnancy',
            'Intrapartum Care',
            'Postnatal Care',
            'Pregnancy & Baby Scan', 
            'Multiple Pregnancy Care'
        ]
    },
    OBSTETRIC_PROCEDURES: {
        name: 'Obstetric Procedures',
        services: [
            'Caesarean Section', 
            'Operative Normal Delivery',
            'Fetal Monitoring',
            'CS Sterilisation'
        ]
    },
    GYNECOLOGY_SERVICES: {
        name: 'Gynecology Services',
        services: [
            'Pelvic infections', 
            'Pelvic Pain',
            'Heavy Periods', 
            'Polycystic Ovaries', 
            'Endometriosis', 
            'Fibroids', 
            'Prolapse', 
            'Menopause', 
            'Post Menopausal Bleeding', 
            'Contraception',
            'Coil Insertion', 
            'STD Screening', 
            'Ovarian Cyst', 
            'Recurrent Miscarriage Management', 
            'Gynae cancer Screening',
            'Amenorrhoea',
            'Adolescent Gynae',
            'Dysfunctional Uterine Bleeding',
            'Osteoprosis',
            'Ectopic pregnancy',
            'Vulval care'
        ]
    },
    GYNAE_PROCEDURES: {
        name: 'Gynae Procedures',
        services: [
            'Hysteroscopy',
            'ERPC',
            'Medical management of Miscarriage',
            'Laparoscopic  Sterilisation',
            'Benign Gynae surgery',
            'Laparoscopic  adhesiolysis',
            'Laparoscopic treatment of endometriosis',
            'Endometrial pippel biopsy',
            'Pessary insertion',
            'Lower genital tract biopsy',
            'Marsupliasation Bartholin cyst',
            'STI & PID screen',
            'Cervical Smears'
        ]
    }
}