import { useTranslation } from "react-i18next";
import { URLs } from "urls";
import "./PinkDetailSection.scss";

interface Props {
  heading: string;
  url: string;
  componentName: string;
}

export const PinkDetailSection = ({ heading, url, componentName }: Props) => {
  const { t } = useTranslation();
  const imageUrl = `${componentName}-detail-section-img`;

  return (
    <>
      <div className={`pink-section d-flex flex-column justify-content-center align-items-center ${imageUrl}`}>
        <h2>{heading}</h2>
        <span className="navigation-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a className="text-decoration-none text-dark" href={URLs.ROOT}>{t("header.links.home")}</a></li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href={url}>{heading}</a>
            </li>
          </ol>
        </span>
      </div>
    </>
  );
};
