import { Blogs } from 'constants/Blogs';
import { flexRowOrColumn, useWindowSize } from 'helper/Classhelper';
import { useEffect, useState } from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import "./BlogsSection.scss"
import { BlogTile } from './BlogTile/Blogtile';

export const BlogsSection = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);
    const [index, setIndex] = useState<number>(0);
  
    const handleSelect = (selectedIndex: number) => {
      setIndex(selectedIndex);
    };

    const showSecondTile = (index: number): JSX.Element => {
        if (isDesktop) {
            if (index > 0) {
                return (<BlogTile blog={Blogs[0]}/>);
            }

            return (<BlogTile blog={Blogs[Blogs.length -1]}/>);
        }

        return <></>;
    }

    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    return (
        <>
            <div className='blogs-detail-section d-flex flex-column'>
                <div className='blogs-detail-sub-section d-flex flex-column justify-content-center align-items-center'>
                    <small className='sub-section-pink-text'>{t('blogs.our')}</small>
                    <span className='sub-section-text text-center'>{t('blogs.latest')}</span>
                </div>
    
                <div className={`blogs-section d-flex justify-content-center align-items-center desktop-only ${flexRowOrColumn(isDesktop)}`}>
                    <Carousel 
                        activeIndex={index}
                        onSelect={handleSelect}
                        controls={false}
                        children= {Blogs.map((blog, index) => (
                            <CarouselItem key={blog.id}>
                                <div className='d-flex flex-row'>
                                    <BlogTile blog={blog}/>

                                    {showSecondTile(index)}
                                </div>
                            </CarouselItem>
                        ))}
                    />
                </div>
            </div>
        </>
    );
}
