import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSpring , animated } from "react-spring";
import { ContactDetailSection } from "shared/components/ContactDetailSection/ContactDetailSection";
import { FindingSolutions } from "shared/components/FindingSolutions/FindingSolutions";
import { OurServicesSection } from "shared/components/OurServicesSection/OurServicesSection";
import { PinkDetailSection } from "shared/components/PinkDetailSection/PinkDetailSection";
import { Testimonial } from "shared/components/Testiomonial/Testimonial";
import { Components } from "shared/constants/components";
import { URLs } from "urls";

export const OurServices = () => {
    const props=useSpring(
        {
            to:{marginRight:0},
            
            from:{marginRight:-300}
        }
    )
    const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
    return(
        <animated.div style={props}>
            <PinkDetailSection heading="Our Services" url={URLs.SERVICES} componentName={Components.SERVICES}/>

            <OurServicesSection />

            <FindingSolutions componentName={Components.SERVICES} />

            <Testimonial />

            <ContactDetailSection />
        </animated.div>
    )
}
