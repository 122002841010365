import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import DoctorImage from 'assets/images/dr-img.svg';
import './DoctorDetailSection.scss';
import { useWindowSize } from "helper/Classhelper";
import { Link } from "react-router-dom";
import { URLs } from "urls";

interface Props {
  isHomepage?: boolean;
}

export const DoctorDetailSection = ({ isHomepage = false }: Props) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);

    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    return (
        <div className='ahsi-section d-flex flex-column justify-content-center align-items-center'>
          <div className='ahsi-sub-section d-flex flex-column justify-content-center align-items-center'>
            <small className='sub-section-pink-text'>{isHomepage ? t('header.links.about') : t('doctor.welcome')}</small>
            <span className='sub-section-text text-center'>{t('doctor.ahsi')}</span>
          </div>

          <div className={`ahsi-doctor-section d-flex justify-content-center align-items-center ${isDesktop ? 'flex-row' : 'flex-column-reverse'}`}>
            <div className={'doctor-detail-section d-flex flex-column ' + (isDesktop ? 'me-5': '')}>
              <span className='doctor-title'>Dr SFZ Feroz (Faah)</span>
            <span className='doctor-expertise'>{isHomepage ? "MBBS, LRCP LRCS LMSSA, MSc, DFFP, MRCOG, PhD" : "" }</span>
            <span className='doctor-description'>
              {isHomepage ? t('doctor.homePage.description') : 
              ( 
                <span>
                  {t('doctor.description.para1')}<br/><br/>
                  {t('doctor.description.para2')}<br/><br/>
                  {t('doctor.description.para3')}<br/><br/>
                  {t('doctor.description.para4')}<br/><br/>
                  {t('doctor.description.para5')}<br/><br/>
                  {t('doctor.description.para6')}<br /><br />
                  {t('doctor.description.para7')}<br/><br/>
                  {t('doctor.description.para8')}<br/><br/>
                  {t('doctor.description.para9')}<br/><br/>
                  {t('doctor.description.para10')}
                </span>
              )
              }
            </span>
              {isHomepage && (
                <span className="mt-5">
                  <Link to={URLs.ABOUT}>
                    <button className="learn-more-btn">
                    {t("shared.learnmore_button")}
                    </button>
                  </Link>
                </span>
              )}
            </div>
            <span className={`doctor-image-section ${!isDesktop && 'mb-59'}`}>
              <img src={DoctorImage} alt='doctor-profile'/>
            </span>
          </div>
        </div>
    );
}