import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const BlogTile = ({ blog }: any) => {
    const { t } = useTranslation();

    return (
        <div className=" d-flex flex-row">
            <div key={blog.title} className={"blog d-flex flex-column justif-content-center"}>
                <Link to={'blog/' + blog.id}><img className="blog-img" src={blog.image} alt="blog-img"/></Link>
                <div className='d-flex flex-column blog-content-section'>
                    <small className="blog-date">{blog.date}</small>
                    <h2 className="blog-title">{blog.title}</h2>
                    <Link to={'blog/' + blog.id}><span className="blog-read-more">{t('blogs.read')}</span></Link>
                </div>
            </div>
        </div>
    );
}
