import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Blogs } from 'constants/Blogs';
import { PinkDetailSection } from "shared/components/PinkDetailSection/PinkDetailSection";
import { URLs } from 'urls';

import './blog.scss'
import { Components } from 'shared/constants/components';

export const Blog = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState<any>();
    
    useEffect(() => {
        if (id) {
            setBlog(Blogs.find((blog) => blog.id === id));
        }
    }, [id]);

    return (
        <>
            {blog ? (
            <>
                <PinkDetailSection heading={blog?.title} url={URLs.BLOG} componentName={Components.BLOG}/>
                    <div className='blog-section'>
                        <div className='blog-detail-section'>
                            <span>
                                {blog.description}
                            </span>
                        </div>
                    </div>
            </>
            ): 
                <PinkDetailSection heading={'Blog not found'} url={URLs.BLOG} componentName={Components.BLOG}/>
            }
        </>
    ); 
}
