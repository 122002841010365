import "./MemberShip.scss";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { MemberShipCard } from "components/MemberShipCard/MemberShipCard";
import { MemberShipServices } from "../../constants/MemberShipServices";
import { useWindowSize } from "helper/Classhelper";
export const MemberShip = () => {
  const { t } = useTranslation();
  const [index, setIndex] = React.useState<number>(0);
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  const silver_service = MemberShipServices.SILVERPLAN_SERVICES;
  const gold_service = MemberShipServices.GOLDPLAN_SERVICES;
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);

  useEffect(() => {
    setIsDesktop(width > 1024);
  }, [width]);
  return (
    <div className="membership-section d-flex flex-column ">
      <div className="membership-sub-heading d-flex justify-content-center ">
        {t("membership.text")}
      </div>
      <div className="membership-heading text-center d-flex justify-content-center mt-3 mb-5">
        {t("membership.heading")}
      </div>
      <Carousel
        className="carousel-section"
        interval={null}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <div className="d-flex flex-row justify-content-center">
            <span className="me-4 card-plan">
              <MemberShipCard
                ServiceName={silver_service.name}
                price={silver_service.price}
                Services={silver_service.Services}
                Number={silver_service.number}
              />
            </span>
            {isDesktop &&
              <span className="card-plan">
                <MemberShipCard
                  ServiceName={gold_service.name}
                  Services={gold_service.Services}
                  price={gold_service.price}
                  Number={gold_service.number}
                />
              </span>
            }
          </div>
        </Carousel.Item>
        {
          !isDesktop
          &&
          <Carousel.Item>
            <div className="d-flex flex-row justify-content-center">
              <span className="card-plan">
                <MemberShipCard
                  ServiceName={gold_service.name}
                  Services={gold_service.Services}
                  price={gold_service.price}
                  Number={gold_service.number}
                />
              </span>
            </div>
          </Carousel.Item>
        }
      </Carousel>
    </div>
  );
};
