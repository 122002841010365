import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Components } from "shared/constants/components";
import { URLs } from "urls";
import { PinkDetailSection } from "../PinkDetailSection/PinkDetailSection";

import './Misc.scss';

interface Props {
    title: string;
    description: string;
}

export const Misc = ({ title, description }: Props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
        
        <PinkDetailSection componentName={Components.BLOG} heading={title} url={URLs.ROOT}/>

        <div className='misc-section'>
            <div className='misc-detail-section'>
                <span>
                    {description}
                </span>
            </div>
        </div>
        </>
    )
}