import { useTranslation } from 'react-i18next'
import FeedBackImage from '../../../assets/images/feedback-bg.svg';
import './FeedBackForm.scss';
import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import { flexRowOrColumn, useWindowSize } from 'helper/Classhelper';
import { toast, ToastContainer } from 'react-toastify';

import { Email } from 'constants/Email';
import 'react-toastify/dist/ReactToastify.css';

export const FeedBackForm = () => {
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);

    useEffect(() => {
        setIsDesktop(width > 1024);
    }, [width]);
    const { t } = useTranslation();
    const [feedback, setFeedBack] = React.useState(true);
    const [complaints, setComplaints] = React.useState(false);
    const [enteredName, setEnteredName] = React.useState("");
    const [enteredEmail, setEnteredEmail] = React.useState("");
    const [enteredFeedBack, setEnteredFeedBack] = React.useState("");
    const [nameValid, setNameValid] = React.useState(false);
    const [emailValid, setEmailValid] = React.useState(false);
    const serviceId = Email.serviceId;
    const userID = Email.userId;
    const templateId = Email.templateId;

    const feedbackClickHandler = (event: any) => {
        setEnteredEmail('');
        setEnteredFeedBack('');
        setEnteredName('');
        event.preventDefault();
        setComplaints(false);
        setFeedBack(true);
    }
    const complaintsClickHandler = (event: any) => {
        event.preventDefault();
        setComplaints(true);
        setEnteredEmail('');
        setEnteredFeedBack('');
        setEnteredName('');
        setFeedBack(false);
    }
    const nameChangeHandler = (event: any) => {
        const name = event.target.value;
        if (name.trim().length > 0) {
            setNameValid(true);
        }
        setEnteredName(event.target.value);
    }
    const feedBackChangeHandler = (event: any) => {
        setEnteredFeedBack(event.target.value);
    }
    const emailChangeHandler = (event: any) => {
        const email = event.target.value;
        if (email.trim().length > 0) {
            setEmailValid(true);
        }
        setEnteredEmail(event.target.value);
    }
    const sendClickHandler = (event: any) => {
        event.preventDefault();
        let validation: boolean = true;
        if (enteredName.trim().length === 0) {
            setNameValid(false);
            validation = false;
        }
        if (enteredEmail.trim().length === 0) {
            setEmailValid(false);
            validation = false;
        }
        if (validation) {
            var params = {
                to_name: enteredName,
                from_name: enteredEmail,
                message: enteredFeedBack,
                email_type: complaints ? "Complaint" : "Feedback"
            };

            emailjs.send(serviceId, templateId, params, userID).then(success => {
                toast('Your valuable feedback is received.');        
            }).catch(error => {
                toast('Error sending your feedback');        
            });

            setEnteredEmail('');
            setEnteredName('');
            setEnteredFeedBack('');
        }
        else {
            return;
        }
    }
    return (
        <div className='feedback-section justify-content-center align-items-center d-flex flex-column'>
            <span className='feedback-sub-heading mb-3'>{t('feedback.sub.heading')}</span>
            <span className='feedback-heading text-center mb-5'>{t('feedback.heading')}</span>
            <div className='mt-3'>
                <form onSubmit={sendClickHandler} id="form">
                    <div className={`d-flex ${flexRowOrColumn(isDesktop)}`}>
                        <div className='d-flex form-responsive flex-column me-4'>
                            {isDesktop ? (
                                <div className="d-flex flex-row feed-buttons">
                                <button className='feedback-toggle-buttons' type="button" onClick={feedbackClickHandler} style={{ backgroundColor: !feedback ? '#FFF0F7' : '#F55FA3', color: !feedback ? 'black' : 'white' }}>FeedBack</button>
                                <button className='feedback-toggle-buttons button-border' type="button" onClick={complaintsClickHandler} style={{ backgroundColor: !complaints ? '#FFF0F7' : '#F55FA3', color: !complaints ? 'black' : 'white' }}>Complaints</button>
                            </div>
                            ): <></>}
                            <div className={`d-flex   mb-5 justify-content-center align-items-center ${flexRowOrColumn(isDesktop)}`}>
                                <div className="d-flex flex-column me-5">
                                    <label className="feedback-label mb-3">Full Name
                                        {!nameValid && <span style={{ color: 'red' }}>*</span>}
                                    </label>
                                    <input type="text" value={enteredName} onChange={nameChangeHandler} placeholder='your name' />
                                </div>
                                <div className="d-flex flex-column me-5 email-input-mobile">
                                    <label className="feedback-label mb-3">Email
                                        {!emailValid && <span style={{ color: 'red' }}>*</span>}
                                    </label>
                                    <input type="email" onChange={emailChangeHandler} value={enteredEmail} placeholder='your email' />
                                </div>
                            </div>
                            <div className="d-flex message-responsive  flex-column mb-5">
                                <label className="feedback-label mb-5">{feedback ? "Your Feedback" : "Your Complaints"}</label>
                                <input type="text" onChange={feedBackChangeHandler} value={enteredFeedBack} className="feedback-comment" placeholder='Hi, this is my feedback' />
                            </div>
                            <button className='feedback-send-button mb-5' type="submit">Send Message</button>
                        </div>
                        <div>
                            <img src={FeedBackImage} alt="feedback-img" />
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}