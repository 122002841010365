import { useTranslation } from "react-i18next";
import { PinkDetailSection } from "shared/components/PinkDetailSection/PinkDetailSection";
import { DoctorDetailSection } from "shared/components/DoctorDetailSection/DoctorDetailSection";
import { FindingSolutions } from "shared/components/FindingSolutions/FindingSolutions";
import { URLs } from "urls";
import { Components } from "shared/constants/components";
import { animated, useSpring } from "react-spring";
import { ContactDetailSection } from "shared/components/ContactDetailSection/ContactDetailSection";
import { Testimonial } from "shared/components/Testiomonial/Testimonial";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const About = () => {
  const { t } = useTranslation();
  const props = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: -500 },
  });
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <animated.div style={props}>
      <PinkDetailSection heading={t("header.links.about")} url={URLs.ABOUT} componentName={Components.ABOUT} />

      <DoctorDetailSection />

      <FindingSolutions componentName={Components.ABOUT} />

      <Testimonial />

      <ContactDetailSection />
    </animated.div>
  );
};
