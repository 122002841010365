import { flexRowOrColumn, useWindowSize } from 'helper/Classhelper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import AsmProjectImage from '../../assets/images/project-logo1.svg';
import SameoProjectImage from '../../assets/images/project-logo2.svg';
import './Projects.scss';

export const Projects = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);

  
    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    return (
        <div className='project-section justify-content-center align-items-center d-flex flex-column'>
            <span className='project-sub-heading mb-3'>{t('project.sub.heading')}</span>
            <span className='project-heading'>{t('project.heading')}</span>
            <div className={`d-flex mt-5 ${flexRowOrColumn(isDesktop)}`}>
            <div className={`project-card d-flex justify-content-center align-items-center ${(isDesktop ? 'mr-42' : 'mt-5')}`}><a href="https://www.arcadia14.org/" target="_blank"><img src={AsmProjectImage} className='project-image' alt='project-img' /></a></div>
            <span className='project-card d-flex justify-content-center align-items-center'><a href="https://www.sameo.org.uk/" target="_blank"><img src={SameoProjectImage} className='project-image' alt='project-img' /></a></span>
            </div>
        </div>
  )
}
