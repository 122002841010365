import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./MemberShipCard.scss";

interface Props {
  ServiceName?: string;
  Number?: string;
  Services?: string[];
  price?: string;
}

export const MemberShipCard = ({
  ServiceName,
  Number,
  Services,
  price,
}: Props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="card-section d-flex flex-column ">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Payment details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div>
            <ul>
              <li><b className="text-pink">Account number:</b> Dummy</li>
              <li><b className="text-pink">Bank:</b> Dummy</li>
              <li><b className="text-pink">Account holder name:</b> Dummy</li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column membership-text">
          <span style={{ textDecoration: "underline" }}>{Number}</span>
          <span>{ServiceName}</span>
        </div>
        <div
          className="d-flex flex-column membership-text membership-price align-items-end"
          style={{ width: Number === "03." ? "62%" : "67%" }}
        >
          <span>Starting at</span>
          <span style={{ color: `var(--ahsi-text-pink)`, fontWeight: '700' }}>{price}</span>
        </div>
      </div>
      <div className="mt-5 mb-4">
        <button onClick={handleShow} className="membership-select-button">Select</button>
      </div>
      <div className="d-flex flex-column card-details">
        <span className="mb-3">What's included?</span>
        <ul>
          {Services?.map((service) => (
            <li key={service} className="mb-3 service-list">
              {service}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
