import { useWindowSize } from "helper/Classhelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLs } from "urls";
import VisionImage from "../../../assets/images/vision.png";
import "./VisionSection.scss";

export const VisionSection = ({ isHomepage = false }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);

  useEffect(() => {
    setIsDesktop(width > 1024);
  }, [width]);

  const getClass = () => {
    if (isHomepage && isDesktop) {
      return 'flex-row';
    }

    if ((isHomepage && !isDesktop) || (!isHomepage && !isDesktop)) {
      return 'flex-column';
    }

    if (!isHomepage && isDesktop) {
      return 'flex-row-reverse';
    }

    return '';
  };

  const getMarginClass = () => {
    if (isHomepage && isDesktop) {
      return 'ml-5'
    }

    if (isHomepage || (!isDesktop && !isHomepage)) {
      return '';
    }

    if (isDesktop && !isHomepage) {
      return 'mr-5'
    }
  }

  return (
    <div className={`vision-section d-flex justify-content-center align-items-center ${getClass()}`}>
      <span className={`vision-image-section  ${!isDesktop && 'mb-59'}`}>
        <img src={VisionImage} alt="doctor-profile" />
      </span>
      <div className={`vision-detail-section ${getMarginClass()} d-flex flex-column`}>
        <div className="sub-section-pink-text mb-3">{t("vision.heading")}</div>
        <div className="vision-heading-text mb-3">{t("vision.description")}</div>
        <div className="vision-text mb-3">{t("vision.paragraph1")}</div>
        <div className="vision-text mb-4">{t("vision.paragraph2")}</div>
        {isHomepage ? (
          <Link to={URLs.WHYAHSI}>
          <button className="learn-more-btn">
            {t("shared.learnmore_button")}
          </button>
        </Link>
        ) : <></>}
      </div>
    </div>
  );
};
