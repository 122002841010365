export enum URLs  {
    ROOT = '/',
    ABOUT = '/about',
    SERVICES = '/services',
    WHYAHSI = '/whyahsi',
    FEEDBACK = '/feedback',
    BOOK_AN_APPOINTMENT = '/booking',
    BLOG = '/blog',
    USER_TERMS = '/userterms',
    PRIVACY = '/privacy',
    COOKIE = '/cookie'
}