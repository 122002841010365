import { useTranslation } from 'react-i18next';
import { Container, Nav, Navbar } from 'react-bootstrap';

import { ReactComponent as InfoIcon } from 'assets/images/info-icon.svg';
import LogoIcon from 'assets/images/logo-icon.svg';
import MenuToggleIcon from 'assets/images/menu-toggle-icon.png';

import './header.scss';
import { Link, useLocation } from 'react-router-dom';
import { URLs } from 'urls';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'helper/Classhelper';

export const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(width > 991);
  const [url, setUrl] = useState(window.location.href);

  useEffect(() => {
    setIsDesktop(width > 991);
  }, [width]);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location])

  return (
      <>
        <div className="d-flex justify-content-center align-items-center section-over-header">
          <InfoIcon id='info-icon' className="mr-1"/>
          {window.innerWidth > 1024 ? t("sectionOverHeader.upToDate") : "Resources, and information on " } <a className="ml-1" href='https://covid.gov.pk/'>COVID-19</a>
        </div>

        <Navbar className="ahsi-header" expand="lg">
          <Container>
            <Navbar.Brand>
              <Link to={URLs.ROOT}>
                <span className="navbar-brand" >
                  <img src={LogoIcon} alt="ahsi-logo"/>
                </span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ padding: '0px' }}>
              <img src={MenuToggleIcon} alt="menu-toggle-icon" />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto navbar-area">
                <div className='cross-sign'>
                  <Navbar.Toggle style={{border:'0px'}}> <span className='cross-text'>&#10005;</span> </Navbar.Toggle>
                </div>
                {isDesktop ? (
                  <>
                    <Link className={"nav-link text-dark " + (url === '/' ? 'active' : '') as string} to={URLs.ROOT}>{t('header.links.home')}</Link>
                    <Link className={"nav-link text-dark "  + (url.indexOf(URLs.ABOUT) > -1 ? 'active' : '') as string} to={URLs.ABOUT}>{t('header.links.about')}</Link>
                    <Link className={"nav-link text-dark "  + (url.indexOf(URLs.WHYAHSI) > -1 ? 'active' : '') as string} to={URLs.WHYAHSI}>{t('header.links.why')} AHSI</Link>
                    <Link className={"nav-link text-dark "  + (url.indexOf(URLs.SERVICES) > -1 ? 'active' : '') as string} to={URLs.SERVICES}>{t('header.links.services')}</Link>
                    <Link className={"nav-link text-dark "  + (url.indexOf(URLs.FEEDBACK) > -1 ? 'active' : '') as string} to={URLs.FEEDBACK}>{t('header.links.feedback')}</Link>
                  </>
                ) : (
                  <>
                    <Navbar.Toggle className='border-0'>
                      <Link className={"nav-link text-dark " + (url === '/' ? 'active' : '') as string} to={URLs.ROOT}>{t('header.links.home')}</Link>
                    </Navbar.Toggle>
                    <hr className='solid-pink'/>
                    <Navbar.Toggle className='border-0'>
                      <Link className={"nav-link text-dark "  + (url.indexOf(URLs.ABOUT) > -1 ? 'active' : '') as string} to={URLs.ABOUT}>{t('header.links.about')}</Link>
                    </Navbar.Toggle>
                    <hr className='solid-pink'/>
                    <Navbar.Toggle className='border-0'>
                      <Link className={"nav-link text-dark "  + (url.indexOf(URLs.WHYAHSI) > -1 ? 'active' : '') as string} to={URLs.WHYAHSI}>{t('header.links.why')} AHSI</Link>
                    </Navbar.Toggle>
                    <hr className='solid-pink'/>
                    <Navbar.Toggle className='border-0'>
                      <Link className={"nav-link text-dark "  + (url.indexOf(URLs.SERVICES) > -1 ? 'active' : '') as string} to={URLs.SERVICES}>{t('header.links.services')}</Link>
                    </Navbar.Toggle>
                    <hr className='solid-pink'/>
                    <Navbar.Toggle className='border-0'>
                      <Link className={"nav-link text-dark "  + (url.indexOf(URLs.FEEDBACK) > -1 ? 'active' : '') as string} to={URLs.FEEDBACK}>{t('header.links.feedback')}</Link>
                    </Navbar.Toggle>
                  </>
                )}
                <hr className='solid-pink'/>
                <li className="nav-item lh-10 header-phone">
                <a className="nav-link d-flex pe-none text-dark justify-content-center align-items-center pl-0" href="/">
                    <img style={{marginRight: '5px'}} src="https://www.freepnglogos.com/uploads/whatsapp-png-logo-1.png" width="20" height="20" alt="whatsapp png logo" />
                  {t('header.links.call')}
                  </a>
                  <span className="phone-number">+44 7772 966307</span>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
  )
}
