import { FindingSolutions } from "shared/components/FindingSolutions/FindingSolutions";
import { PinkDetailSection } from "shared/components/PinkDetailSection/PinkDetailSection";
import { VisionSection } from "shared/components/VisionSection/VisionSection";
import { Components } from "shared/constants/components";
import { URLs } from "urls";
import { CoreValuesSection } from "./CoreValuesSection/CoreValuesSection";
import { useSpring, animated } from "react-spring";
import { useWindowSize } from "helper/Classhelper";
import { useEffect, useState } from "react";
import { ContactDetailSection } from "shared/components/ContactDetailSection/ContactDetailSection";
import { Testimonial } from "shared/components/Testiomonial/Testimonial";
import { useLocation } from "react-router-dom";

export const WhyAHSI = () => {
  const props = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: -200 },
  });
  const {width}=useWindowSize();
  const [isDesktop,setIsDesktop]=useState<boolean>(width>786);
  const { pathname } = useLocation();
  
  useEffect(() => {
    setIsDesktop(width > 1024);
  }, [width]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <animated.div style={props}>
      <PinkDetailSection heading="Why AHSI" url={URLs.WHYAHSI} componentName={Components.WHYAHSI} />

      <VisionSection />

      {isDesktop && <CoreValuesSection />}

      <FindingSolutions componentName={Components.WHYAHSI} />

      <Testimonial />

      <ContactDetailSection />
    </animated.div>
  );
};
