import { useWindowSize } from 'helper/Classhelper';
import { useEffect, useState } from 'react';
import './SharedButton.scss';

interface Props
{
    btnText: string;
    btnOnClick?: (event:any )=> void;
    isLearnMoreButton?: boolean;
    isBookingButton?: boolean;
}

export const SharedButton = ({
    btnText,
    btnOnClick =  (event: any) => {},
    isLearnMoreButton = false,
    isBookingButton = false
}: Props ) =>
{
    const { width } = useWindowSize();
    const [isDesktop, setIsDesktop] = useState<boolean>(width > 1024);

    useEffect(() => {
      setIsDesktop(width > 1024);
    }, [width]);

    const buttonBackground = () => {
        if (isBookingButton) {
            return 'var(--ahsi-text-pink)';
        }

        if ((isLearnMoreButton) || (!isLearnMoreButton && !isBookingButton && !isDesktop)) {
            return 'white';
        }

        return 'transparent';
    }

    const buttonBorder = () => {
        if (isBookingButton || isLearnMoreButton) {
            return '1px solid #f55fa3';
        }

        return '1px solid #FFF';
    }

    return (
                <div
                    style={
                            {
                                backgroundColor: buttonBackground(),
                                color: (isLearnMoreButton) || (!isLearnMoreButton && !isBookingButton && !isDesktop) ? 'var(--ahsi-text-pink)' : 'white',
                                border: buttonBorder(),
                                textDecorationColor: buttonBackground()
                            }
                        }
                    onClick={btnOnClick}
                    className="btn-style"
                >
                        {btnText}
                    </div>
    );
}
